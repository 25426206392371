<template>
  <div class="home">
    <el-container style="height: calc(100vh - 38px)">
      <el-aside :width="isCollapse2 ? '64px' : '256px'" class="silde">
        <el-menu :collapse-transition="false" :default-openeds="['table_1', 'table_2', 'table_3', 'table_4', 'table_5']"
          :default-active="activeMenu" @select="handleSelect" :collapse="isCollapse2"
          :class="isCollapse1 ? 'close-menu' : ''">
          <el-menu-item index="index" v-if="role == 4">
            <img class="menu-icon" v-if="isIndex" :src="require('@/assets/menu/index_a.png')" alt="">
            <img class="menu-icon" v-else :src="require('@/assets/menu/index.png')" alt="">
            <span v-if="isCollapse2">主页</span>
            <span v-else slot="title" :style="{ color: activeMenu == 'chart' ? '#409EFF' : '#303133' }">主页</span>
          </el-menu-item>
          <el-menu-item index="chart" v-if="role == 1 || role == 2 || role == 3">
            <img class="menu-icon" v-if="isChart" :src="require('@/assets/menu/chart_a.png')" alt="">
            <img class="menu-icon" v-else :src="require('@/assets/menu/chart.png')" alt="">
            <span v-if="isCollapse2">数据仪表盘</span>
            <span v-else slot="title" :style="{ color: activeMenu == 'chart' ? '#409EFF' : '#303133' }">数据仪表盘</span>
          </el-menu-item>
          <el-submenu index="table_1" v-if="role == 4">
            <template slot="title">
              <img class="menu-icon" v-if="isSchoolType" :src="require('@/assets/menu/list_a.png')" alt="">
              <img class="menu-icon" v-else :src="require('@/assets/menu/list.png')" alt="">
              <span slot="title" :style="{ color: isSchoolType ? '#409EFF' : '#303133' }">机构质量评估</span>
            </template>
            <el-menu-item index="school_not_submit"
              :style="{ color: activeMenu == 'school_not_submit' ? '#409EFF' : '#303133' }">未提交列表</el-menu-item>
            <el-menu-item index="school_submitted"
              :style="{ color: activeMenu == 'school_submitted' ? '#409EFF' : '#303133' }">已提交列表</el-menu-item>
            <el-menu-item index="school_reviewed"
              :style="{ color: activeMenu == 'school_reviewed' ? '#409EFF' : '#303133' }">已审核列表</el-menu-item>
          </el-submenu>
          <el-submenu index="table_1" v-if="role == 3">
            <template slot="title">
              <img class="menu-icon" v-if="isSchoolType" :src="require('@/assets/menu/list_a.png')" alt="">
              <img class="menu-icon" v-else :src="require('@/assets/menu/list.png')" alt="">
              <span slot="title" :style="{ color: isSchoolType ? '#409EFF' : '#303133' }">机构自评管理</span>
            </template>
            <el-menu-item index="school_submitted"
              :style="{ color: activeMenu == 'school_submitted' ? '#409EFF' : '#303133' }">已提交列表</el-menu-item>
            <el-menu-item index="school_review"
              :style="{ color: activeMenu == 'school_review' ? '#409EFF' : '#303133' }">评审中列表</el-menu-item>
            <el-menu-item index="school_reviewed"
              :style="{ color: activeMenu == 'school_reviewed' ? '#409EFF' : '#303133' }">已评审列表</el-menu-item>
          </el-submenu>
          <el-submenu index="table_1" v-if="role == 2 || role == 1">
            <template slot="title">
              <img class="menu-icon" v-if="isSchoolType" :src="require('@/assets/menu/list_a.png')" alt="">
              <img class="menu-icon" v-else :src="require('@/assets/menu/list.png')" alt="">
              <span slot="title" :style="{ color: isSchoolType ? '#409EFF' : '#303133' }">机构自评管理</span>
            </template>
            <el-menu-item index="school_submitted"
              :style="{ color: activeMenu == 'school_submitted' ? '#409EFF' : '#303133' }">未审核列表</el-menu-item>
            <el-menu-item index="school_review"
              :style="{ color: activeMenu == 'school_review' ? '#409EFF' : '#303133' }">评审中列表</el-menu-item>
            <el-menu-item index="school_reviewed"
              :style="{ color: activeMenu == 'school_reviewed' ? '#409EFF' : '#303133' }">已评审列表</el-menu-item>
          </el-submenu>
          <el-submenu index="table_2" v-if="role == 1 || role == 2 || role == 3">
            <template slot="title">
              <img class="menu-icon" v-if="isCityType" :src="require('@/assets/menu/city_a.png')" alt="">
              <img class="menu-icon" v-else :src="require('@/assets/menu/city.png')" alt="">
              <span slot="title" :style="{ color: isCityType ? '#409EFF' : '#303133' }">区县他评管理</span>
            </template>
            <el-menu-item v-if="role == 3" index="city_create"
              :style="{ color: activeMenu == 'city_create' ? '#409EFF' : '#303133' }">创建他评</el-menu-item>
            <el-menu-item index="city_review"
              :style="{ color: activeMenu == 'city_review' ? '#409EFF' : '#303133' }">评审中列表</el-menu-item>
            <el-menu-item index="city_reviewed"
              :style="{ color: activeMenu == 'city_reviewed' ? '#409EFF' : '#303133' }">已评审列表</el-menu-item>
          </el-submenu>
          <el-submenu index="table_3" v-if="role == 1 || role == 2">
            <template slot="title">
              <img class="menu-icon" v-if="isCountryType" :src="require('@/assets/menu/country_a.png')" alt="">
              <img class="menu-icon" v-else :src="require('@/assets/menu/country.png')" alt="">
              <span slot="title" :style="{ color: isCountryType ? '#409EFF' : '#303133' }">市级他评管理</span>
            </template>
            <el-menu-item index="country_create"
              :style="{ color: activeMenu == 'country_create' ? '#409EFF' : '#303133' }">创建督评</el-menu-item>
            <el-menu-item index="country_review"
              :style="{ color: activeMenu == 'country_review' ? '#409EFF' : '#303133' }">评审中列表</el-menu-item>
            <el-menu-item index="country_reviewed"
              :style="{ color: activeMenu == 'country_reviewed' ? '#409EFF' : '#303133' }">已评审列表</el-menu-item>
          </el-submenu>
          <el-submenu index="table_4" v-if="role == 1 || role == 2">
            <template slot="title">
              <img class="menu-icon" v-if="isOrganActive" :src="require('@/assets/menu/org_a.png')" alt="">
              <img class="menu-icon" v-else :src="require('@/assets/menu/org.png')" alt="">
              <span slot="title" :style="{ color: isOrganActive ? '#409EFF' : '#303133' }">机构管理</span>
            </template>
            <el-menu-item index="organ_list"
              :style="{ color: activeMenu == 'organ_list' ? '#409EFF' : '#303133' }">机构列表</el-menu-item>
            <el-menu-item index="user_list"
              :style="{ color: activeMenu == 'user_list' ? '#409EFF' : '#303133' }">机构用户列表</el-menu-item>
          </el-submenu>
          <el-submenu index="table_5" v-if="role == 1">
            <template slot="title">
              <img class="menu-icon" v-if="isAdminActive" :src="require('@/assets/menu/admin_a.png')" alt="">
              <img class="menu-icon" v-else :src="require('@/assets/menu/admin.png')" alt="">
              <span slot="title" :style="{ color: isAdminActive ? '#409EFF' : '#303133' }">管理员管理</span>
            </template>
            <el-menu-item index="admin_list"
              :style="{ color: activeMenu == 'admin_list' ? '#409EFF' : '#303133' }">管理员列表</el-menu-item>
            <!-- <el-menu-item index="unit_list" :style="{color: activeMenu=='unit_list'?'#409EFF':'#303133'}">卫健委单位管理</el-menu-item> -->
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header class="header">
          <router-link to="/" tag="div" v-if="!isCollapse2" >
            <div class="logo" :class="isCollapse2 ? 'collapse_logo' : ''">伊犁州托育机构质量评估标准平台</div>
          </router-link>
          <div class="title">
            <i class="collapse" :class="isCollapse2 ? 'el-icon-s-unfold' : 'el-icon-s-fold'" @click="toggleMenu"></i>
          </div>
          <el-dropdown class="user" @command="logout">
            <div class="user-wrap">
              <el-avatar class="avatar" :size="38" fit="cover" :src="circleUrl" @error="errorHandler" placement="bottom">
                <img style="width: 100%; height: 100%" :src="require('../assets/index/avatar.png')" alt="" /></el-avatar>
              <span class="user-name ellipsis">{{ name }}</span>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="a" v-if="role == 4"><span>机构信息</span>
              </el-dropdown-item>
              <el-dropdown-item command="b"><span>修改密码</span>
              </el-dropdown-item>
              <!-- <el-dropdown-item command="d" v-if="role == 4"><span>换绑手机</span>
            </el-dropdown-item> -->
              <el-dropdown-item command="c"><span>退出登录</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-header>
        <el-main class="main">
          <router-view />
        </el-main>
      </el-container>
    </el-container>
    <el-drawer title="" size="368px" :visible.sync="drawer" direction="rtl">
      <div class="drawer">
        <!-- <h3>用户使用教程视频</h3>
      <img :src="require('../assets/index/qa_video_cover.png')" alt="" @click="dialogVisible = true" /> -->
        <!-- <div style="font-size: 22px;font-weight:600;margin: 0;">托育SaaS云<a href="http://tuoyusaas.com/" target="_blank" style="color:#0083F5;font-size: 22px;text-align:center">http://tuoyusaas.com/</a></div>
      <el-divider></el-divider> -->
        <h3>使用帮助</h3>
        <h4>老师您好，我是您的评估专家</h4>
        <div class="user">
          <p style="margin: 10px 0;">评估实施咨询电话：</p>
          <p style="font-size: 22px;color:#0083F5;font-weight:600;margin: 0;">010-66023794</p>
          <p style="font-size: 14px;color:#666; margin: 10px 0 30px">工作日：9:00-11:30 ~ 13:30-17:00</p>
        </div>
        <h4>老师您好，我是您的技术客服</h4>
        <div class="user">
          <div class="avatar">
            <img :src="require('../assets/index/qa_avatar.png')" alt="" />
            <p style="margin: 0">杜老师</p>
          </div>
          <p>您在使用平台的过程中出现任何问题，随时与我们联系。</p>
          <img class="qr" :src="require('../assets/index/qa_qr.png')" alt="" />
          <p class="phone">客服电话：010-57182596</p>
        </div>
        <p class="book pdf">
          <a href="https://www.tuoyufuwu.org.cn/detail.html?id=1431&type=article" target="_blank">
            关于发布推荐性卫生行业标准
            <br> 《托育机构质量评估标准》的通告
            <br> (国卫通〔2023〕13号)
          </a>
        </p>
      </div>
    </el-drawer>
    <el-dialog title="用户操作视频" :visible.sync="dialogVisible" @close="dialogClose" width="70%">
      <video class="video" controls v-if="dialogVideoUrl" width="100%" :src="dialogVideoUrl" alt="" />
    </el-dialog>
    <el-footer id="footer">
      <span>ICP备案编号：京ICP备13043694号</span>
      <span><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502049964" target="_blank"
          style="text-decoration: none;color: #fff;cursor: pointer;"><img src="../assets/index/beian.png"
            style="float:left;margin-right:4px;" />京公网安备 11010502049964号</a></span>
      <span>技术支持：京学集团</span>
    </el-footer>
  </div>
</template>

<script>
let pagewidth = document.body.clientWidth;
export default {
  name: "Home",
  data() {
    return {
      circleUrl: "",
      isCollapse1: false,
      isCollapse2: pagewidth < 1800,
      drawer: false,
      role: "",
      name: '',
      username: '',
      activeMenu: '',
      dialogTitle: '',
      dialogVisible: false,
      dialogVideoUrl: 'dialogVideoUrl',
    };
  },
  computed: {
    isTableActive() {
      return this.activeMenu == "school_not_submit" || this.activeMenu == "school_submitted" || this.activeMenu == "school_reviewed" || this.activeMenu == "school_review" || this.activeMenu == "city_create" || this.activeMenu == "city_reviewed" || this.activeMenu == "city_reviewed" || this.activeMenu == "country_create" || this.activeMenu == "country_reviewed" || this.activeMenu == "country_reviewed";
    },
    isSchoolType() {
      let t = this.activeMenu;
      return t == "school_not_submit" || t == "school_submitted" || t == "school_reviewed" || t == "school_review";
    },
    isCityType() {
      let t = this.activeMenu;
      return t == "city_create" || t == "city_review" || t == "city_reviewed";
    },
    isCountryType() {
      let t = this.activeMenu;
      return t == "country_create" || t == "country_review" || t == "country_reviewed";
    },
    isAdminActive() {
      return this.activeMenu == "admin_list" || this.activeMenu == "unit_list"
    },
    isOrganActive() {
      return this.activeMenu == "organ_list" || this.activeMenu == "user_list"
    },
    isIndex() {
      return this.activeMenu == "index"
    },
    isChart() {
      return this.activeMenu == "chart"
    },
  },
  methods: {
    dialogClose() {
      this.dialogVisible = false;
    },
    errorHandler() {
      return false;
    },
    toggleMenu() {
      if (this.isCollapse1 == true) {
        this.isCollapse1 = false;
        setTimeout(() => {
          this.isCollapse2 = false;
        }, 0);
      } else {
        this.isCollapse1 = true;
        setTimeout(() => {
          this.isCollapse2 = true;
        }, 0);
      }
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => { });
    },
    handleSelect(key, keyPath) {
      if (key == 'city_create' || key == 'country_create') {
        this.activeMenu = key;
        if (key == 'city_create') {
          this.$router.push('/table/city_review');
        } else {
          this.$router.push('/table/country_review');
        }
        setTimeout(() => {
          this.$router.push('/inspect/' + key);
        }, 0);
        return
      }
      let path = [...keyPath];
      if (path[0].indexOf('_') != -1) {
        path[0] = path[0].split('_')[0];
      }
      this.$router.push("/" + path.join("/"));
      this.activeMenu = key;
      console.log(key, keyPath, this.activeMenu);
    },
    logout(e) {
      console.log("logout", e);
      if (e == 'c') {
        localStorage.clear();
        this.$router.push("/user/login");
      } else if (e == 'a') {
        this.$router.push('/user_organ/check')
      } else if (e == 'b') {
        this.$router.push('/user/password')
      } else if (e == 'd') {
        this.$router.push('/bind_mobile')
      }
    },
    jump() {
      window.open('https://saas.tuoyufuwu.org.cn/cscpa/index.html#/autologin?username=' + this.username)
    },
    updateMenu(to) {
      let p = to.path;
      if (p.indexOf('index') != -1) {
        this.activeMenu = 'index'
      } else if (p.indexOf('chart') != -1) {
        this.activeMenu = 'chart'
      } else if (p.indexOf('table') != -1) {
        this.activeMenu = to.params.type
      }
      console.log(this.activeMenu)
    }
  },
  mounted() {
    this.role = localStorage.getItem("role") * 1;
    this.name = localStorage.getItem("name");
    this.username = localStorage.getItem("username");
    console.log('mounted', this.username)
    // this.updateMenu();

  },
  beforeRouteUpdate(to, from, next) {
    console.log('beforeRouteUpdate')
    console.log(to)
    this.updateMenu(to);
    sessionStorage.setItem('backPage', '');
    next()
  },
  components: {
    // HelloWorld
  },
};
</script>

<style lang="scss" scoped>
.home {
  background: #f3f6fb;

  .menu-icon {
    width: 18px;
    margin: 0 10px;
  }

  .silde {
    padding-top: 78px;
    background: #fff;
    padding-bottom: 50px;
  }

  .header {
    position: fixed;
    background-color: #fff;
    color: #333;
    box-shadow: 0px 6px 13px 0px rgba(22, 127, 204, 0.13);
    z-index: 9;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    left: 0;
    top: 0;
    padding: 0;
    color: rgb(81, 90, 110);
    font-size: 16px;
    height: 68px;

    .logo {
      margin: 4px 10px 0 20px;
      height: 40px;
      display: flex;
      align-items: center;
      font-weight: bold;
      cursor: pointer;
      color: #000;
    }

    .collapse_logo {
      height: 40px;
      margin: 0 20px;
    }

    .title {
      flex: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .collapse {
        margin: 0 30px;
        font-size: 18px;
      }

      .qa {
        cursor: pointer;
        display: flex;
        align-items: center;

        .qa-icon {
          margin: 0 8px;
          width: 18px;
        }
      }
    }

    .user {
      // flex: 0 0 350px;
      margin: 0 40px 0 20px;

      .user-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        cursor: pointer;
      }

      .avatar {
        margin-right: 12px;
        flex: 0 0 38px;
      }

      .user-name {
        max-width: 200px;
      }
    }
  }

  .main {
    padding-top: 78px;
    padding-bottom: 50px;
  }

  .drawer {
    width: 368px;
    padding: 0 38px;
    text-align: center;

    h3 {
      font-size: 20px;
      margin-bottom: 20px;
      text-align: center;
    }

    >img {
      cursor: pointer;
    }

    p {
      color: 000;
      font-size: 16px;
    }

    .book {
      margin-top: 26px;
      margin-bottom: 42px;
      text-align: center;

      span {
        color: #0083f5;
        cursor: pointer;
      }
    }

    .pdf {
      display: flex;
      align-items: center;
      justify-content: center;

      a,
      span {
        color: #0083f5;
        text-decoration: none;
      }

      span {
        display: inline-block;
        height: 18px;
        background: #d2d2d2;
        width: 1px;
        margin: 0 22px;
      }

      a:hover {
        color: #333;
      }
    }

    h4 {
      font-size: 18px;
      margin-bottom: 17px;
      text-align: center;
    }

    .user {
      text-align: center;

      .avatar {
        display: flex;
        align-items: center;
        justify-content: center;

        >img {
          width: 42px;
          margin-right: 7px;
        }
      }

      p {
        font-size: 14px;
        margin: 20px auto;
        text-align: center;
      }

      .qr {
        width: 130px;
      }

      p:last-of-type {
        margin-top: 10px;
      }
    }
  }
}
</style>

<style lang="scss">
.home {

  .el-submenu__title,
  .el-menu-item {
    text-align: left;
    display: flex;
    align-items: center;
  }

  .el-submenu .el-menu-item {
    text-align: left;
    padding-left: 58px !important;
  }

  .close-menu {

    .el-submenu__title>span,
    .el-submenu .el-menu-item,
    .el-menu-item>span {
      display: none !important;
    }
  }

  .el-menu {
    border-right: none;
  }

  .el-avatar {
    background: #fff;
  }
}
</style>
